import { constants } from '../../helpers/constants'

const initialState = { loading: true, error: '' }

export function merchant(state = initialState, action) {
  switch (action.type) {
  case constants.PAGE_LOADING: {
    return {
      ...state,
      loading: true,
    }
  }

  case constants.MERCHANT_REQUEST_SUCESS: {
    return {
      ...state,
      merchants: action.payload,
      loading: false,
    }
  }

  default:
    return state
  }
}
