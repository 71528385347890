import React from 'react'
import { forgotPasswordRequest } from '../../app/store/actions'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { ForgotPasswordForm }  from 'sc-sdk'

function ForgotPassword() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleFormSubmit = (formData) => {
    dispatch(forgotPasswordRequest(formData, navigate))
  }

  return (
    <div className='container'>
      <div className='col-md-4 offset-4'>
        <div className='vh-100 d-flex align-items-center justify-content-center'>
          <div className='vw-100'>
            <h5 className='mb-4'>Forgot Password?</h5>
            <ForgotPasswordForm 
              handleFormSubmit={handleFormSubmit}
            />
            <Link
              to='/'
              className='d-flex align-items-center justify-content-center'
            >
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
