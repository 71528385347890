import { constants } from '../../../app/helpers/constants'
import { takeLatest, call, put } from 'redux-saga/effects'
import Axios from 'axios'

function* userLogin(action) {
  try {
    const uri = `${process.env.REACT_APP_API_URL}/login`
    const response = yield call(Axios.post, uri, action.formData)
    if (response.data.status === 'ok') {
      const { user } = response.data
      localStorage.setItem('user', user.token)
      localStorage.setItem('registration_type', user.registration_type)
      yield put({ type: constants.LOGIN_USER, payload: user })
      yield put({ type: constants.SUCCESS, payload: 'Sucessfully login' })
      if (user.otp === true) {
        action.navigate('/otp_verification')
      } else {
        action.navigate('/')
      }
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userOtp(action) {
  try {
    const uri = `${process.env.REACT_APP_API_URL}/otp`
    const response = yield call(Axios.post, uri, action.formData)
    if (response.data.status === 'ok') {
      localStorage.setItem('user', response.data.user.token)
      action.navigate('/')
      yield put({ type: constants.LOGIN_USER, payload: response.data.user })
      yield put({ type: constants.SUCCESS, payload: response.data.message })
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userRegistrationRequest(action) {
  try {
    const uri = `${process.env.REACT_APP_API_URL}/registration`
    const response = yield call(Axios.post, uri, action.formData)
    if (response.data.status === 'ok') {
      action.navigate('/login')
      yield put({ type: constants.SUCCESS, payload: response.data.message })
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userForgotPassword(action) {
  try {
    const uri = `${process.env.REACT_APP_API_URL}/forgot_password`
    const response = yield call(Axios.post, uri, action.formData)
    if (response.data.status === 'ok') {
      action.navigate('/login')
      yield put({ type: constants.SUCCESS, payload: response.data.message })
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userChangePassword(action) {
  try {
    const uri = `${process.env.REACT_APP_API_URL}/reset_password`
    const response = yield call(Axios.post, uri, action.formData)
    if (response.data.status === 'ok') {
      action.navigate('/login')
      yield put({ type: constants.SUCCESS, payload: response.data.message })
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userUnlockRequest(action) {
  try {
    const uri = `${process.env.REACT_APP_API_URL}/request_unlock`
    const response = yield call(Axios.post, uri, action.formData)
    if (response.data.status === 'ok') {
      action.navigate('/login')
      yield put({ type: constants.SUCCESS, payload: response.data.message })
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userUnlockAccountConfirmRequest(action) {
  try {
    const uri = `${process.env.REACT_APP_API_URL}/unlock_account`
    const response = yield call(Axios.post, uri, action.formData)
    if (response.data.status === 'ok') {
      yield put({ type: constants.SUCCESS, payload: response.data.message })
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userSocialLoginRequest(action) {
  try {
    const uri = `${process.env.REACT_APP_API_URL}/social_login`
    const response = yield call(Axios.post, uri, action.formData)
    if (response.data.status === 'ok') {
      localStorage.setItem('user', JSON.stringify(response.data.access_token))
      action.navigate('/')
      yield put({ type: constants.SUCCESS, payload: response.data.message })
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userProfileUpdateRequest(action) {
  try {
    const uri = `${process.env.REACT_APP_API_URL}/profie_update`
    const response = yield call(Axios.post, uri, action.formData)
    if (response.data.status === 'ok') {
      yield put({ type: constants.SUCCESS, payload: response.data.message })
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userRequest() {
  let token = localStorage.getItem('user')
  try {
    const uri = `${process.env.REACT_APP_API_URL}/user_details`
    const response = yield call(Axios.post, uri, { token })
    if (response.data.status === 'ok') {
      yield put({ type: constants.LOGIN_USER, payload: response.data.user })
    } else {
      yield put({ type: constants.ERROR, payload: response.data.error })
    }
  } catch {
    yield put({ type: constants.ERROR, payload: 'Failed' })
  }
}

function* userLogoutRequest(action) {
  const user = localStorage.getItem('user')
  if (user) {
    try {
      localStorage.clear()
      yield put({ type: constants.LOGIN_USER, payload: {} })
      action.navigate('/login')
      yield put({ type: constants.SUCCESS, payload: 'Sucessfully Logout' })
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  }
}

function* watchAll() {
  yield takeLatest(constants.LOGIN_REQUEST, userLogin)
  yield takeLatest(constants.OTP_REQUEST, userOtp)
  yield takeLatest(constants.REGISTRATION_REQUEST, userRegistrationRequest)
  yield takeLatest(constants.FORGOT_PASSWORD_REQUEST, userForgotPassword)
  yield takeLatest(constants.CHANGE_PASSWORD_REQUEST, userChangePassword)
  yield takeLatest(constants.UNLOCK_REQUEST, userUnlockRequest)
  yield takeLatest(
    constants.ACCOUNT_UNLOCK_CONFIRM_REQUEST,
    userUnlockAccountConfirmRequest,
  )
  yield takeLatest(constants.SOCIAL_LOGIN_REQUEST, userSocialLoginRequest)
  yield takeLatest(constants.UPDATE_PROIFLE_REQUEST, userProfileUpdateRequest)
  yield takeLatest(constants.LOGOUT_REQUEST, userLogoutRequest)
  yield takeLatest(constants.USER_DETAILS_REQUEST, userRequest)
}

export default watchAll
