import { constants } from '../../helpers/constants'

const initialState = { loading: true, error: '' }

export function admin(state = initialState, action) {
  switch (action.type) {
  case constants.PAGE_LOADING: {
    return {
      ...state,
      loading: true,
    }
  }

  case constants.ADMIN_MERCHANT_LIST_SUCESS: {
    return {
      ...state,
      merchants: action.payload,
      loading: false,
    }
  }

  case constants.ADMIN_MERCHANT_VIEW_SUCESS: {
    return {
      ...state,
      view: state.merchants.find((item) => item.id === action.payload),
      loading: false,
    }
  }

  case constants.ADMIN_MERCHANT_DELETE_SUCESS: {
    return {
      ...state,
      merchants: state.merchants.filter((item) => item.id !== action.payload),
      loading: false,
    }
  }

  case constants.ADMIN_MERCHANT_ITEM_SUCESS: {
    return {
      ...state,
      items: action.payload,
      loading: false,
    }
  }

  case constants.ADMIN_MERCHANT_INFLUENCER_RELATIONSHIPS_SUCESS: {
    return {
      ...state,
      manage_influencers: action.payload,
      loading: false,
    }
  }

  case constants.ADMIN_INFLUENCER_LIST_SUCESS: {
    return {
      ...state,
      influencers: action.payload,
      loading: false,
    }
  }

  case constants.ADMIN_INFLUENCER_VIEW_SUCESS: {
    return {
      ...state,
      view: state.influencers.find((item) => item.id === action.payload),
      loading: false,
    }
  }

  case constants.ADMIN_INFLUENCER_REMOVE_SUCESS: {
    return {
      ...state,
      influencers: state.influencers.filter(
        (item) => item.id !== action.payload,
      ),
      loading: false,
    }
  }

  default:
    return state
  }
}
