import React, { useEffect, useState } from 'react'
import { adminInfluencerRequest } from '../../app/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ViewRequest } from '../components'
import { List }   from 'sc-sdk'

function Influencers() {
  const dispatch = useDispatch()
  const [modal, setModal] = useState({show: false, id: null});
  
  const {
    influencers,
    view,
    items,
    manage_influencers
  } = useSelector((state) => state.admin) || {};

  useEffect(() => {
    dispatch(adminInfluencerRequest('list', 1))
  }, [])

  const handleAddForm = (type, item) => {
    dispatch(adminInfluencerRequest(type, item?.id))
    setModal({show: true, id: item?.id})
  }

  const hanldeChangePageRequest = () => {}

  const handleSearchFilter = (data) => {
    console.log(data)
  }

  return (
    <main className='p-4'>
      <List 
        data={influencers}
        type="merchant"
        page_title="Manage Influencers"
        page_header={true}
        handleAddForm={handleAddForm}
        filter_options={[]}
        handleSearchFilter={handleSearchFilter}
        filter_acitve={true}
        hanldeChangePageRequest={hanldeChangePageRequest}
      />
      {modal.show && 
        <ViewRequest
          onHide={() => setModal(false)}
          handleApi={handleAddForm}
          view={view}
          title={view?.email}
          id={modal.id}
          items={items}
          influencers={manage_influencers}
          user_type="Influencer"
        />
      }
    </main>
  )
}

export default Influencers
