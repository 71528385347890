import { all, fork } from 'redux-saga/effects'
import * as users from './user'
import * as invite from './invite'
import * as admin from './admin'

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(users),
      ...Object.values(admin),
      ...Object.values(invite),
    ].map(fork),
  )
}
