import { constants } from '../../helpers/constants'

const initialState = { loading: true, error: '' }

export function influencer(state = initialState, action) {
  switch (action.type) {
  case constants.PAGE_LOADING: {
    return {
      ...state,
      loading: true,
    }
  }

  case constants.GET_INFLUENCER_SUCESS: {
    return {
      ...state,
      list: action.payload,
      page_count: 10,
      loading: false,
    }
  }

  case constants.INFLUENCER_VIEW_SUCESS: {
    return {
      ...state,
      view: state.list.find((item) => item.id === action.payload),
      loading: false,
    }
  }

  case constants.INFLUENCER_ITEMS_SUCESS: {
    return {
      ...state,
      influencer_items: action.payload,
      loading: false,
    }
  }

  case constants.INFLUENCER_PAYMENT_HISTORY_SUCESS: {
    return {
      ...state,
      influencer_payments: action.payload,
      loading: false,
    }
  }

  case constants.DELETE_INVITE_INFLUENCER_SUCESS: {
    return {
      ...state,
      invite: state.invite.filter((item) => item.id !== action.payload),
      loading: false,
    }
  }

  case constants.MERCHANT_REQUEST_SUCESS: {
    return {
      ...state,
      merchants: action.payload,
      loading: false,
    }
  }

  default:
    return state
  }
}
