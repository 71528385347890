import React from 'react'
import { 
  Modal,
  Row,
  Col,
  Table
} from 'react-bootstrap'
import { ModalHeader,NoRecord, RemoveUnderscore }   from 'sc-sdk'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

const InformationBlock = ({ title, items }) => {
  return (
    <Col md={12}>
      <h6 className='mt-2 mb-3'>{title}</h6>
      {items && Object.entries(items).map(([key, value]) => (
        <Row className="view_div" key={key}>
          <Col md={5} className="pe-0">
            <span className="key text-capitalize">{RemoveUnderscore(key)} :</span>
          </Col>
          <Col md={7}>
            <span className="value">{value}</span>
          </Col>
        </Row>
      ))}
    </Col>
  );
};

function ViewRequest({onHide, view, id, title, items, influencers, user_type, handleApi}) {
  
  const handleSelect = (key) => {
    handleApi(key, id)
  }

  console.log(view)

  return (
    <Modal
      show={true}
      size='lg'
      centered
      className='customModal'
      onHide={onHide}
    >
      <ModalHeader
        title={`View - ${title}`}
        handleClose={onHide}
      />
      <div className='px-4 py-4'>
        <Tabs
          defaultActiveKey='view'
          onSelect={handleSelect}
          id='fill-tab-example'
          className='setup_tabs'
        >
          <Tab eventKey='view' title='View'>
            <div className='tab_panel'>
              <Row>
                <InformationBlock title="Organization Information" items={view?.organization} />
                <InformationBlock title="Contact Information" items={view?.contact} />
                <InformationBlock title="Bank Information" items={view?.bank} />
              </Row>
            </div>
          </Tab>
          <Tab eventKey='items' title='Items'>
            <div className='tab_panel'>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className='text-center' width='5'>
                      S.No
                    </th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Commission</th>
                    <th>Image Url</th>
                  </tr>
                </thead>
                <tbody>
                  {items && items.length > 0 ? (
                    items.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className='text-center'>{data.id}</td>
                          <td>{data.name}</td>
                          <td>${data.price}</td>
                          <td>${data.commission}</td>
                          <td>
                            {data.image_url.map((item, index) => {
                              return (
                                <p className='mb-1' key={index}>
                                  {item.url}
                                </p>
                              )
                            })}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <NoRecord colSpan='6' />
                  )}
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab
            eventKey='influencers'
            title={
              user_type === 'Merchant' ? 'Influencers' : 'Sub Influencers'
            }
          >
            <div className='tab_panel'>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className='text-center'>ID</th>
                    <th>Name</th>
                    <th>E-mail</th>
                  </tr>
                </thead>
                <tbody>
                  {influencers && influencers.length > 0 ? (
                    influencers.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className='text-center'>{data.id}</td>
                          <td>{data.name}</td>
                          <td>{data.email}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <NoRecord colSpan='8' />
                  )}
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
      </div>
    </Modal>
  )
}

export {ViewRequest}
