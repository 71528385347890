import React from 'react'
import { useNavigate } from 'react-router-dom'
import { loginRequest } from '../../app/store/actions'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoginForm }  from 'sc-sdk'

function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const on_sucess = (sucess) => {
    dispatch(loginRequest(sucess, navigate))
  }

  const on_failure= () => {}

  return (
    <div className='container'>
      <div className='col-md-4 offset-4'>
        <div className='vh-100 d-flex align-items-center justify-content-center'>
          <div className='vw-100'>
            <h5 className='mb-4'>Login</h5>
            <LoginForm 
              on_sucess={on_sucess}
              on_failure={on_failure}
              forgot_password={true}
            />
            <div className='d-flex justify-content-center mb-2'>
              <span>Don&apos;t have an account?</span>
              <Link to='/registration' className='px-2'>
                {' '}
                Sign Up
              </Link>
            </div>
            <p className='d-flex justify-content-center'>
              <Link to='/unlock/request'>Request unlock email</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
