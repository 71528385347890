import { constants } from '../../../app/helpers/constants'
import { takeLatest, call, put } from 'redux-saga/effects'
import { invite_influencer } from '../../../app/helpers/json'
import Axios from 'axios'

function* inviteRequest(action) {
  if (action.request === 'list') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.INVITE_REQUEST_SUCESS,
          payload: invite_influencer,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'request') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri)
      if (response.data.status === 'ok') {
        yield put({ type: constants.SUCCESS, payload: response.data.status })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'delete') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.DELETE_INVITE_USER_SUCESS,
          payload: action.formData,
        })
        yield put({ type: constants.SUCCESS, payload: 'Sucessfully delete' })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'approval') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri)
      if (response.data.status === 'ok') {
        yield put({ type: constants.SUCCESS, payload: response.data })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  }
}

function* watchAll() {
  yield takeLatest(constants.INVITE_REQUEST, inviteRequest)
}

export default watchAll
