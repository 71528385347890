import React from 'react'

function Dashboard() {
  return (
    <div className='d-flex align-items-center justify-content-center vh-100 '>
      <div className='text-center'>
        <h5>Welcome Admin Dashboard</h5>
      </div>
    </div>
  )
}

export default Dashboard
