export const constants = {
  PAGE_LOADING: 'page_loading',

  // alert
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',

  // login
  LOGIN_REQUEST: 'Login_request',
  REGISTRATION_REQUEST: 'registration_request',
  FORGOT_PASSWORD_REQUEST: 'forgot password request',
  CHANGE_PASSWORD_REQUEST: 'change password request',
  UNLOCK_REQUEST: 'unlock_request',
  ACCOUNT_UNLOCK_CONFIRM_REQUEST: 'account_unlock_confirm_request',
  SOCIAL_LOGIN_REQUEST: 'social_login_request',
  UPDATE_PROIFLE_REQUEST: 'update_profile_request',
  LOGOUT_REQUEST: 'logout_requst',
  LOGIN_USER: 'Login user',
  AUTHENTICATION_FAILED: 'authentication_failed',
  OTP_REQUEST: 'otp_request',
  USER_DETAILS_REQUEST: 'user_details_request',
  REGISTRATION_CONTACT_INFORMATION: 'registration_contact_information',
  REGISTRATION_ORGANIZATION_INFORMATION:
    'registration_organization_information',
  REGISTRATION_GENERAL_INFORMATION: 'registration_general_information',

  // admin
  ADMIN_LOGIN_REQUEST: 'admin_login_request',
  ADMIN_LOGIN_USER: 'admin_login_user',
  ADMIN_MERCHANT_REQUEST: 'admin_merchant_request',
  ADMIN_MERCHANT_LIST_SUCESS: 'admin_merchant_list_sucess',
  ADMIN_MERCHANT_VIEW_SUCESS: 'admin_merchant_view_sucess',
  ADMIN_MERCHANT_DELETE_SUCESS: 'admin_merchant_delete_sucess',
  ADMIN_MERCHANT_ITEM_SUCESS: 'admin_merchant_item_sucess',
  ADMIN_MERCHANT_INFLUENCER_RELATIONSHIPS_SUCESS:
    'admin_merchant_influencer_relationships_sucess',
  ADMIN_MERCHANT_BANK_SUCESS: 'admin_merchant_bank_sucess',

  ADMIN_INFLUENCER_REQUEST: 'influencer_request',
  ADMIN_INFLUENCER_LIST_SUCESS: 'admin_influencer_list_sucess',
  ADMIN_INFLUENCER_VIEW_SUCESS: 'admin_influencer_view_sucess',
  ADMIN_INFLUENCER_REMOVE_SUCESS: 'admin_influencer_remove_sucess',
  ADMIN_INFLUENCER_BANK_SUCESS: 'admin_influencer_bank_sucess',

  //influencer request
  INFLUENCER_EQUEST: 'influencer_request',
  GET_INFLUENCER_SUCESS: 'get_influencer_sucess',
  INFLUENCER_ITEMS_SUCESS: 'influencer_items_sucess',
  EDIT_INFLUENCER_SUCESS: 'edit_influencer_sucess',
  VIEW_INFLUENCER_SUCESS: 'view_influencer_sucess',
  UPDATE_INFLUENCER_SUCESS: 'update_influencer_sucess',
  INFLUENCER_PAYMENT_HISTORY_SUCESS: 'influencer_payment_history_sucess',
  INFLUENCER_VIEW_SUCESS: 'influencer_view_sucess',

  // orders
  ORDER_REQUEST: 'order_request',
  LIST_ORDER_SUCESS: 'list_order_sucess',
  VIEW_ORDER_SUCESS: 'view_order_sucess',

  // item request
  ITEM_REQUEST: 'item_request',
  LIST_ITEM_SUCESS: 'list_item_sucess',
  ADD_ITEM_SUCESS: 'add_item_sucess',
  VIEW_ITEM_SUCESS: 'view_item_sucess',
  EDIT_ITEM_SUCESS: 'edit_item_sucess',
  DELETE_ITEM_SUCESS: 'delete_item_sucess',
  UPDATE_ITEM_SUCESS: 'update_item_sucess',

  // agreement term request
  AGREEMENTS_REQUEST: 'agreements_request',
  AGREEMENTS_SUCESS: 'agreements_sucess',
  ADD_AGREEMENT_SUCESS: 'add_agreement_sucess',
  EDIT_AGREEMENT_SUCESS: 'edit_agreement_sucess',
  DELETE_AGREEMENT_SUCESS: 'delete_agreement_sucess',
  UPDATE_AGREEMENT_SUCESS: 'update_agreement_sucess',

  // team request
  TEAM_REQUEST: 'team_request',
  LIST_TEAM_SUCESS: 'list_team_sucess',
  ADD_TEAM_SUCESS: 'add_team_sucess',
  VIEW_TEAM_SUCESS: 'view_team_sucess',
  EDIT_TEAM_SUCESS: 'edit_team_sucess',
  UPDATE_TEAM_SUCESS: 'updat_team_sucess',
  DELETE_TEAM_SUCESS: 'delete_team_sucess',

  //integration request
  INTEGRATION_REQUEST: 'integration_request',

  //invite influencer request
  INVITE_REQUEST: 'invite_request',
  INVITE_REQUEST_SUCESS: 'invite_request_sucess',
  DELETE_INVITE_USER_SUCESS: 'delete_invite_user_sucess',

  //item promotion
  ITEM_PROMOTION_REQUEST: 'item_promotion_request',
  ITEM_PROMOTION_SUCESS: 'item_promotion_sucess',
  ITEM_PROMOTION_PRODUCT: 'item_promotion_product',
  ITEM_PROMOTION_INFLUENCER: 'item_promotion_influencer',
  PROMOTION_REQUEST: 'promotion_request',
  MERCHANT_REQUEST: 'merchant_request',
  MERCHANT_REQUEST_SUCESS: 'merchant_request_sucess',

  // notification
  NOTIFICATION_REQUEST: 'notification_request',
  NOTIFICATION_REQUEST_SUCESS: 'notification_request_sucess',
  NOTIFICATION_DELETE_SUCESS: 'notification_delete_sucess',
}
