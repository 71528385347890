import { constants } from '../../helpers/constants'

export const loginRequest = (formData, navigate) => {
  return {
    type: constants.LOGIN_REQUEST,
    formData,
    navigate,
  }
}

export const registrationRequest = (formData, navigate) => {
  return {
    type: constants.REGISTRATION_REQUEST,
    formData,
    navigate,
  }
}

export const forgotPasswordRequest = (formData, navigate) => {
  return {
    type: constants.FORGOT_PASSWORD_REQUEST,
    formData,
    navigate,
  }
}

export const changePasswordRequest = (formData, navigate) => {
  return {
    type: constants.CHANGE_PASSWORD_REQUEST,
    formData,
    navigate,
  }
}

export const unlockRequest = (formData, navigate) => {
  return {
    type: constants.UNLOCK_REQUEST,
    formData,
    navigate,
  }
}

export const accountUnlockConfirmRequest = (formData) => {
  return {
    type: constants.ACCOUNT_UNLOCK_CONFIRM_REQUEST,
    formData,
  }
}

export const socialLoginRequest = (formData, navigate) => {
  return {
    type: constants.SOCIAL_LOGIN_REQUEST,
    formData,
    navigate,
  }
}

export const otpRequest = (formData, navigate) => {
  return {
    type: constants.OTP_REQUEST,
    formData,
    navigate,
  }
}

export const updateProifleRequest = (formData) => {
  return {
    type: constants.UPDATE_PROIFLE_REQUEST,
    formData,
  }
}

export const userDetailsRequest = () => {
  return {
    type: constants.USER_DETAILS_REQUEST,
  }
}

export const logoutRequest = (navigate) => {
  return {
    type: constants.LOGOUT_REQUEST,
    navigate,
  }
}
