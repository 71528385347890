import { constants } from '../../helpers/constants'


export const inviteRequest = (request, formData) => {
  return {
    type: constants.INVITE_REQUEST,
    request,
    formData,
  }
}

