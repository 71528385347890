import { constants } from '../../helpers/constants'

export const adminMerchantRequest = (request, formData) => {
  return {
    type: constants.ADMIN_MERCHANT_REQUEST,
    request,
    formData,
  }
}

export const adminInfluencerRequest = (request, formData) => {
  return {
    type: constants.ADMIN_INFLUENCER_REQUEST,
    request,
    formData,
  }
}
