import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import './assets/App.scss'
import React from 'react'
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  BrowserRouter as Router,
} from 'react-router-dom'
import Alert from './components/Alert'
import ErrorScreen from './error'
import SideNavBar from './shared/sidebar'
import Login from './auth/login'
import ResendConfirmationMail from './auth/forgot_password'
import ChangePassword from './auth/change_password'
import RequestUnlock from './auth/requestUnlock'
import RequestUnlockToken from './auth/unlockAccount'
import Dashboard from './dashboard/index'
import Merchants from './merchants/index'
import Influencers from './influencers/index'
import InviteInfluencer from './influencers/invite_influencer'
import InviteMerchant from './merchants/invite_merchant'

function App() {

  localStorage.setItem('app_type', 'SCA');

  const isUserLoggedIn = localStorage.getItem('user') ? true : false

  function PrivateRoute() {
    return isUserLoggedIn ? <Outlet /> : <Navigate to='/login' replace />
  }

  function AnonymousRoute() {
    return isUserLoggedIn ? <Navigate to='/' replace /> : <Outlet />
  }

  return (
    <Router>
      <div className='container-fluid p-0'>
        {isUserLoggedIn ? (
          <SideNavBar/>
        ) : (
          ''
        )}
        <Alert />
        <div
          className={
            isUserLoggedIn
              ? ' min-vh-100 col-md-9 col-lg-10 ml-md-auto px-4 ms-md-auto'
              : ''
          }
        >
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route exact path='/' element={<Dashboard />} />
              <Route
                path='/invite/influencer'
                element={<InviteInfluencer />}
              />
              <Route
                path='/invite/merchant'
                element={<InviteMerchant />}
              />
              <Route path='/merchants' element={<Merchants />} />
              <Route path='/influencers' element={<Influencers />} />
            </Route>
            <Route element={<AnonymousRoute />}>
              <Route path='/login' element={<Login />} />
              <Route
                path='/password/new'
                element={<ResendConfirmationMail />}
              />
              <Route path='/password/edit' element={<ChangePassword />} />
              <Route path='/unlock/request' element={<RequestUnlock />} />
              <Route path='/unlock_account' element={<RequestUnlockToken />} />
            </Route>
            <Route path='/*' element={<ErrorScreen />} />
          </Routes>
        </div>
      </div>
    </Router>
  )
}

export default App
