import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { accountUnlockConfirmRequest } from '../../app/store/actions'

function AccountConfirm() {
  const dispatch = useDispatch()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const un_token = query.get('unlock_token')

  useEffect(() => {
    dispatch(accountUnlockConfirmRequest({ unlock_token: un_token }))
  })

  return (
    <main className='d-flex align-items-center justify-content-center'></main>
  )
}

export default AccountConfirm
