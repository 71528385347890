import React, { useEffect, useState } from 'react'
import { inviteRequest } from '../../app/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { FormRequest} from '../components'
import { List }   from 'sc-sdk'

function Merchants() {
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)

  const {invite_users} = useSelector((state) => state.invite) || {}

  useEffect(() => {
    dispatch(inviteRequest('list', 1))
  }, [dispatch])


  const handleAddForm = (type, id) => {
    console.log(type)
    console.log(id)
    type !== "delete" && setModal(true);
  }

  const handleInviteRequest = () => {}

  return (
    <main className='p-4'>
      <List 
        data={invite_users}
        type="invite"
        page_title="Manage Invite Influencers"
        button_name="Invite Influencers"
        page_header={true}
        update_permission={true}
        handleAddForm={handleAddForm}
      />
      
      {modal && 
        <FormRequest 
          handleInviteRequest={handleInviteRequest}
          onHide={() => setModal(false)}
          title="Invite Influencers"
        />
      }
    </main>
  )
}

export default Merchants
