import { constants } from '../../helpers/constants'

const initialState = { loading: true, error: '' }

export function invite(state = initialState, action) {
  switch (action.type) {
  case constants.PAGE_LOADING: {
    return {
      ...state,
      loading: true,
    }
  }

  case constants.INVITE_REQUEST_SUCESS: {
    return {
      ...state,
      invite_users: action.payload,
      loading: false,
    }
  }

  case constants.DELETE_INVITE_USER_SUCESS: {
    return {
      ...state,
      invite_users: state.invite_users.filter(
        (item) => item.id !== action.payload,
      ),
      loading: false,
    }
  }

  default:
    return state
  }
}
