import { constants } from '../../helpers/constants'

export function alert(state = {}, action) {
  switch (action.type) {
  case constants.SUCCESS:
    return {
      type: 'alert-success',
      message: action.payload,
    }
  case constants.ERROR:
    return {
      type: 'alert-danger',
      message: action.payload,
    }
  case constants.CLEAR:
    return {}
  default:
    return state
  }
}
