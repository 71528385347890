import React from 'react';
import { alertActions} from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';

const GlobalAlert = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  const isObjectEmpty = Object.keys(alert).length === 0;

  setTimeout(() => dispatch(alertActions.clear()), 10000)

  const clearAlert = () => {
    dispatch(alertActions.clear());
  }

  return (
    <>
      { !isObjectEmpty && (
        <div className="alert-container">
          <div className={ `alert ${alert.type}` }>
            { alert.message }
            <button
              type="button"
              className="btn-close"
              onClick={clearAlert} />
          </div>
        </div>
      )}
    </>
  )
}

export default GlobalAlert
