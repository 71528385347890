import React from 'react'
import { useNavigate } from 'react-router-dom'
import { unlockRequest } from '../../app/store/actions'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { UnlockRequestForm } from 'sc-sdk';

function UnlockRequest() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleFormSubmit = (formData) => {
    dispatch(unlockRequest(formData, navigate))
  }

  return (
    <div className='container'>
      <div className='col-md-4 offset-4'>
        <div className='vh-100 d-flex align-items-center justify-content-center'>
          <div className='vw-100'>
            <h5 className='mb-4'>Request Unlock Your Account</h5>
            <UnlockRequestForm 
              Link={Link}
              handleFormSubmit={handleFormSubmit}
            />
            <div className='text-center'>
              <Link to='/login'>Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnlockRequest
