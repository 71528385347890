import React from 'react'
import { InviteSchema } from '../components'
import { yupResolver } from '@hookform/resolvers/yup'
import { Row, Col, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Input, Button, ModalHeader, CKEditor5 }  from 'sc-sdk'

let description =
  "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. <a href='/registraion'> join as Click here</a>"

function FormRequest(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(InviteSchema),
  })

  const onSubmit = (data) => {
    props.handleInviteRequest(data)
    reset()
    props.onHide()
  }

  const handleClose = () => {
    reset()
    props.onHide()
  }

  return (
    <Modal
      show={true}
      size='lg'
      centered
      className='customModal'
      onHide={handleClose}
    >
      <ModalHeader 
        title={`${props.title}`}
        handleClose={handleClose}
      />

      <div className='px-4 py-4'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className='d-flex align-items-center mb-3'>
            <CKEditor5 
              data={description}
              reset={reset} 
              label="Invite Requst Content"
            />
            <Col md={6} className='pe-0'>
              <Input
                type='email'
                label='Email'
                name='email'
                register={register}
                error={errors.email?.message}
              />
            </Col>
            <Col md={1} className='text-center mt-1'>
              OR
            </Col>
            <Col md={5} className='ps-0'>
              <Input
                type='number'
                label='Phone Number'
                name='phone_number'
                register={register}
                error={errors.phone_number?.message}
              />
            </Col>
          </Row>
          <Button
            type="submit"
            variant='primary'
            size='md'
            button_text="Invite Requst"
          />
        </form>
      </div>
    </Modal>
  )
}

export {FormRequest}
