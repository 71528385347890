import React from 'react'

function ErrorScreen() {
  return (
    <div className='d-flex align-items-center justify-content-center vh-100 '>
      <div className='text-center'>
        <h4>404</h4>
        <h5>Page not found</h5>
      </div>
    </div>
  )
}

export default ErrorScreen
