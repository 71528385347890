import React, { useEffect, useState } from 'react'
import { adminMerchantRequest } from '../../app/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ViewRequest } from '../components'
import { List }   from 'sc-sdk'

function Merchants() {
  const dispatch = useDispatch()
  const [modal, setModal] = useState({show: false, id: null});

  const { 
    merchants,
    view,
    items,
    manage_influencers
  } = useSelector((state) => state.admin) || {};

  useEffect(() => {
    dispatch(adminMerchantRequest('list', 1))
  }, [dispatch])


  const handleAddForm = (type, item) => {
    dispatch(adminMerchantRequest(type,  item?.id))
    setModal({show: true, id: item?.id})
  }

  const hanldeChangePageRequest = () => {}
  
  const handleSearchFilter = () => {}

  return (
    <main className='p-4'>
      <List 
        data={merchants}
        type="Merchant"
        page_title="Manage Merchants"
        page_header={true}
        handleAddForm={handleAddForm}
        filter_options={[]}
        filter_acitve={true}
        handleSearchFilter={handleSearchFilter}
        hanldeChangePageRequest={hanldeChangePageRequest}
      />

      {modal.show && 
        <ViewRequest
          onHide={() => setModal(false)}
          handleApi={handleAddForm}
          view={view}
          title={view?.email}
          id={modal.id}
          items={items}
          influencers={manage_influencers}
          user_type="Merchant"
        />
      }

    </main>
  )
}

export default Merchants
