import React from 'react';
import reportWebVitals from './reportWebVitals';
import store from './app/store/saga/rootStore'
import ReactDOM from 'react-dom/client';
import { Provider} from 'react-redux'
import App from './app/layout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
