import { constants } from '../../helpers/constants'

const initialState = { loading: true, error: '' }

export function user(state = initialState, action) {
  switch (action.type) {
  case constants.PAGE_LOADING: {
    return {
      ...state,
      loading: true,
    }
  }

  case constants.LOGIN_USER: {
    let user = action.payload
    return {
      ...state,
      user: user,
      loading: false,
    }
  }

  case constants.REGISTRATION_GENERAL_INFORMATION: {
    return {
      ...state,
      general_information: action.payload,
    }
  }

  case constants.REGISTRATION_ORGANIZATION_INFORMATION: {
    let organization_information = {
      ...state.general_information,
      ...action.payload,
    }
    return {
      ...state,
      organization_information: organization_information,
    }
  }

  case constants.REGISTRATION_CONTACT_INFORMATION: {
    let contact_information = {
      ...state.organization_information,
      ...action.payload,
    }
    return {
      ...state,
      contact_information: contact_information,
    }
  }

  default:
    return state
  }
}
