import { constants } from '../../../app/helpers/constants'
import { takeLatest, call, put } from 'redux-saga/effects'
import {
  admin_merchants,
  marketingAssets,
  influencers,
} from '../../../app/helpers/json'
import Axios from 'axios'

function* adminMerchantRequest(action) {
  if (action.request === 'list') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_MERCHANT_LIST_SUCESS,
          payload: admin_merchants,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'view') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri, action.formData)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_MERCHANT_VIEW_SUCESS,
          payload: action.formData,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'remove') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri, action.formData)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_MERCHANT_DELETE_SUCESS,
          payload: action.formData,
        })
        yield put({ type: constants.SUCCESS, payload: 'Remove sucessfully' })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'items') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri, action.formData)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_MERCHANT_ITEM_SUCESS,
          payload: marketingAssets,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'influencers') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri, action.formData)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_MERCHANT_INFLUENCER_RELATIONSHIPS_SUCESS,
          payload: influencers,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'bank') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri, action.formData)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_MERCHANT_BANK_SUCESS,
          payload: influencers,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  }
}

function* adminInfluencerRequest(action) {
  if (action.request === 'list') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_INFLUENCER_LIST_SUCESS,
          payload: admin_merchants,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'view') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_INFLUENCER_VIEW_SUCESS,
          payload: action.formData,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'remove') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_INFLUENCER_REMOVE_SUCESS,
          payload: action.formData,
        })
        yield put({ type: constants.ERROR, payload: "Sucessfully delete" })
      } else {
        yield put({ type: constants.ERROR, payload: "Sucessfully delete" })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'items') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri, action.formData)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_MERCHANT_ITEM_SUCESS,
          payload: marketingAssets,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'influencers') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri, action.formData)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_MERCHANT_INFLUENCER_RELATIONSHIPS_SUCESS,
          payload: influencers,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  } else if (action.request === 'bank') {
    try {
      const uri = `${process.env.REACT_APP_API_URL}/sample_request`
      const response = yield call(Axios.post, uri, action.formData)
      if (response.data.status === 'ok') {
        yield put({
          type: constants.ADMIN_MERCHANT_BANK_SUCESS,
          payload: influencers,
        })
      } else {
        yield put({ type: constants.ERROR, payload: response.data.error })
      }
    } catch {
      yield put({ type: constants.ERROR, payload: 'Failed' })
    }
  }
}

function* watchAll() {
  yield takeLatest(constants.ADMIN_MERCHANT_REQUEST, adminMerchantRequest)
  yield takeLatest(constants.ADMIN_INFLUENCER_REQUEST, adminInfluencerRequest)
}

export default watchAll
