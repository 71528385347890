import { combineReducers } from 'redux'
import { user } from './user'
import { alert } from './alert'
import { influencer } from './influencer'
import { invite } from './invite'
import { admin } from './admin'
import { merchant } from './merchant'

const rootReducer = combineReducers({
  alert,
  user,
  invite,
  admin,
  merchant,
  influencer,
})

export default rootReducer
