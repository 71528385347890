export const admin_menus = [
  { 
    id: 0, 
    title: 'Dashboard', 
    link: '/',
    icon: 'dashboard',
    arrow: false
  },
  
  {
    id: 1,
    title: 'Merchants',
    link: 'merchants',
    icon: 'users',
    arrow: false,
  },

  { 
    id: 3,
    title: 'Invite Merchants',
    link: 'invite/merchant',
    icon: 'users',
    arrow: false,
  },

  {
    id: 1,
    title: 'Influencers',
    link: 'influencers',
    icon: 'users',
    arrow: false,
  },
  { 
    id: 3,
    title: 'Invite Influencer',
    link: '/invite/influencer',
    icon: 'users',
    arrow: false,
  }

];



export const orderFilter = [
  { id: 1, name: 'paid' },
  { id: 2, name: 'Unpaid' },
  { id: 3, name: 'Incomplete' },
  { id: 4, name: 'Cancelled' },
  { id: 5, name: 'Refunded' },
  { id: 6, name: 'Hidden' },
]

export const affiliateFilter = [
  { id: 1, name: 'Approved' },
  { id: 2, name: 'Blocked' },
  { id: 3, name: 'Not Approved' },
  { id: 4, name: 'Reached Payout' },
  { id: 5, name: 'Exclude Payouts' },
]

export const influencers = [
  {
    id: 1,
    name: 'influencer_001',
    email: 'influencer_001@yopmail.com',
    paid_orders: 2,
    unpaid_orders: 5,
    unpaid_earnings: 100.0,
    paid_earnings: 3.0,
    date: '22.10.2023 ',
  },
  {
    id: 2,
    name: 'influencer_002',
    email: 'influencer_002@yopmail.com',
    paid_orders: 10,
    unpaid_orders: 0,
    unpaid_earnings: 200.0,
    paid_earnings: 9.0,
    date: '25.11.2023 ',
  },
  {
    id: 3,
    name: 'influencer_003',
    email: 'influencer_003@yopmail.com',
    paid_orders: 20,
    unpaid_orders: 0,
    unpaid_earnings: 300.0,
    paid_earnings: 5.0,
    date: '26.12.2023 ',
  },
]

export const marketingAssets = [
  {
    id: 1,
    name: 'T-Shirt Red color',
    price: 200,
    commission: 10,
    image_url: [
      {
        url: 'https://picsum.photos/200/300',
      },
      {
        url: 'https://picsum.photos/200/300',
      },
    ],
    description: 'Lorem ipsum dolor sit amet,  ea commodo consequat',
  },

  {
    id: 2,
    name: 'T-Shirt round neck',
    price: 500,
    commission: 10,
    image_url: [
      {
        url: 'https://picsum.photos/200/300',
      },
      {
        url: 'https://picsum.photos/200/300',
      },
    ],
    description: 'Lorem ipsum dolor sit amet,  ea commodo consequat',
  },
]

export const marketingAssetsSingle = {
  name: 'T-Shirt Red color',
}

export const invite_influencer = [
  {
    id: 1,
    email: 'invite_001@yopmail.com',
  },

  {
    id: 2,
    email: 'invite_002@yopmail.com',
  },

  {
    id: 3,
    email: 'invite_003@yopmail.com',
  },

  {
    id: 4,
    email: 'invite_004@yopmail.com',
  },
]

export const influencerUser = [
  {
    id: 1,
    name: 'Approved',
  },
  {
    id: 2,
    name: 'Blocked',
  },
  {
    id: 3,
    name: 'Not Approved',
  },
  {
    id: 4,
    name: 'Reached Payout',
  },
  {
    id: 5,
    name: 'Exclude Payouts',
  },
]

export const admin_merchants = [
  {
    id: 1,
    email: 'leonard@yopmail.com',
    organization: {
      organization_name: 'abc organization',
      organization_email: 'abc@yopmail.com',
      phone_number: 9087654321,
      url: 'http://localhost:8080',
    },
    contact: {
      first_name: 'Adam',
      last_name: 'Abraham',
      phone_number: 9087654321,
      address: '4/90, karyanchavadi',
      street: 'vaniyar street',
      city: 'poonamallee',
      state: 'Tamil Nadu',
      postal_code: '600056',
    },
    bank: {
      routing_number: 'Ax009087FD890',
      account_number: 'AC009879BDKL001',
    },
  },
  {
    id: 2,
    email: 'sebastian@yopmail.com',
    organization: {
      organization_name: 'def organization',
      organization_email: 'def @yopmail.com',
      phone_number: 9087654321,
      url: 'http://localhost:8080',
    },
    contact: {
      first_name: 'Michael',
      last_name: 'Chapman',
      phone_number: 9087654321,
      address: '4/90, karyanchavadi',
      street: 'vaniyar street',
      city: 'poonamallee',
      state: 'Tamil Nadu',
      postal_code: '600056',
    },
    bank: {
      routing_number: 'Ax009087FD890',
      account_number: 'AC009879BDKL001',
    },
  },
]
