import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { changePasswordRequest } from '../../app/store/actions'
import { Link } from 'react-router-dom'
import { ChangePasswordForm }  from 'sc-sdk'

function ChangePassword() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const reset_password_token = query.get('token')

  const handleFormSubmit = (formData) => {
    dispatch(
      changePasswordRequest({ ...formData, reset_password_token }, navigate),
    )
  }

  return (
    <div className='container'>
      <div className='col-md-4 offset-4'>
        <div className='vh-100 d-flex align-items-center justify-content-center'>
          <div className='vw-100'>
            <h5 className='mb-4'>Change Your Password</h5>
            <ChangePasswordForm 
              handleFormSubmit={handleFormSubmit}
            />
            <div className='text-center'>
              <Link to='/login'>Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
