import React, { useEffect } from 'react'
import { logoutRequest } from '../../app/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { admin_menus} from '../../app/helpers/json'
import { AppSideBar }   from 'sc-sdk'

function SideBar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    // dispatch(userDetailsRequest())
  }, [dispatch])

  const { pathname } = useLocation();
  const key_words = pathname.split('/').pop();
  
  const handleLogout = () => {
    logoutRequest(navigate)
  }

  const active_menu = ['merchant'].includes(key_words);
  // const active_sub_menu = ['users', 'clients'].includes(key_words);
  // const open = ['users', 'clients'].includes(key_words);

  return (
    <AppSideBar 
      app_name="SC Admin"
      user_name={user && user.email}
      menu_items={admin_menus}
      acitve_menu={active_menu}
      active_sub_menu={[]}
      handleLogout={handleLogout}
    />  
  )
}

export default SideBar
